import React from 'react'
import { FiArrowRight } from "react-icons/fi";
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import FooterThree from '../common/footer/FooterThree';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import AboutFour from '../elements/about/AboutFour';
import TimelineTwo from '../elements/timeline/TimelineTwo';
import WhatsButton from '../common/WhatsApp';
import AboutTwo from '../elements/about/AboutTwo';
import AbouThree from '../elements/about/AboutThree';
import { useEffect, useRef } from 'react';


function HomeCity(){

    const refs = {
        avaluos : useRef(),
        proceso: useRef(),
        faq: useRef(),
        contacto: useRef()
    }

    function goTo(section) {
        switch (section){
            case 'avaluos':
                refs.avaluos.current.scrollIntoView({behavior: 'smooth'});
                break;
            case 'proceso':
                refs.proceso.current.scrollIntoView({behavior: 'smooth'});
                break;
            case 'faq':
                refs.faq.current.scrollIntoView({behavior: 'smooth'});
                break;
            case 'contacto':
                refs.contacto.current.scrollIntoView({behavior: 'smooth'});
                break;
            default:
                break;
        }

        
    }

    useEffect(() => {
        if(window.matchMedia('(prefers-color-scheme: dark)').matches){
            document.querySelector('body').classList.toggle("light")
        }
        
        
      }, []);
    
    return (
        <>
            <SEO title="City Home del Centro" />
            <WhatsButton />
            <main className="page-wrapper">
                <HeaderOne  btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" refs={goTo}/>

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-850 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/banner/banner-city.png)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <span className="subtitle">CITY HOME DEL CENTRO</span>
                                    <h1 className="title display-one">Unidad de <span>Valuación</span></h1>
                                    <p className="description">Somos una empresa con más de 15 años de experiencia en el mercado.</p>
                                    <div className="button-group">
                                        <a className="btn-default btn-medium round btn-icon" target="_blank" rel="noreferrer" href="https://wa.me/524773268480">Cotizar avalúo<i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <div ref={refs.avaluos}>
                    <AboutFour image="./images/about/contact-image.jpg" />

                    <AboutTwo image="./images/about/ciudad.jpg"/>
                </div>
                
                

                <div className="rwt-timeline-area rn-section-gap" ref={refs.proceso}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Proceso"
                                        title = "Pasos para obtener tu avalúo"
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 ">
                                <TimelineTwo classVar="no-gradient"  />
                            </div>
                        </div>
                    </div>
                </div>

                <div ref={refs.faq}>
                    <AbouThree image="./images/about/preguntas.jpg" />
                </div>
                
                <div ref={refs.contacto}>
                    <FooterThree />  
                </div>
                                

            </main>
            
        </>
    )
}
export default HomeCity
