import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {FiCheck } from "react-icons/fi";

const Tipos = [
    "Locales Comerciales",
    "Terrenos",
    "Casas y Departamentos",
    "Bodegas",
    "Naves Industriales"
]

const AboutTwo = (props) => {
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src={props.image} alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">Valuamos todo tipo de inmuebles.</span></h4>
                                    <h2 className="title mt--10">Algunos de los tipos de inmuebles con los que trabajamos.</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <ul className="list-icon">
                                        {Tipos.map(item => {
                                            return(
                                                <li key={item}><span className="icon"><FiCheck /></span> {item}</li>
                                            )
                                        })}
                                    </ul>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutTwo;
