import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const Preguntas = [
    {
        pregunta: "¿Qué documentación se requiere para un avalúo comercial?",
        respuesta: "Documento que acredite la propiedad, predial (en caso de inmuebles), datos de solicitante y propietario, comprobantes de servicios."
    },
    {
        pregunta: "¿Cuáles son los requisitos para las casas de INFONAVIT?",
        respuesta: "Contar con todos los servicios, tener mínimo 40 metros de construcción, tener mínimo 3 años de antigüedad y máximo 30 años."
    },
    {
        pregunta: "¿Cuáles son los requisitos para las casas de FOVISSSTE?",
        respuesta: "Contar con todos los servicios, contar con mínimo 2 recámaras, tener mínimo 3 años de antigüedad y máximo 30 años."
    },
    {
        pregunta: "¿Cuánto tiempo tarda en estar listo el avalúo?",
        respuesta: "Después de tener toda la documentación requerida y la visita al inmueble tenemos tiempo de entrega de 3 días máximo."
    },
    {
        pregunta: "¿Cuánto cuesta un avalúo?",
        respuesta: "Varia por el tipo de avalúo, ofrecemos servicios desde $1,300 MXN."
    },
    {
        pregunta: "¿Qué cobertura tienen?",
        respuesta: "Tenemos cobertura en toda la república Mexicana."
    }
]

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            {Preguntas.map((item, index) => {
                return(
                    <Card key={index}>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey={index.toString()}>
                            {item.pregunta}
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={index.toString()}>
                            <Card.Body>{item.respuesta}</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                )
            })}
            

        </Accordion>
    )
}
export default AccordionOne

