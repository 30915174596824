import React from "react";
import {FaWhatsapp} from 'react-icons/fa'

class WhatsButton extends React.Component {

    render(){
        return(
            <a
                href={"https://wa.me/524773268480"}
                className="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
            >
                <FaWhatsapp/>
            </a>
        )
    }
}

export default WhatsButton;