import React from 'react';
import {Link} from "react-router-dom";

function Nav(props){
    return (
        <ul className="mainmenu" onClick={props.onClose}>
            <li>
                <Link to="/">Inicio</Link>
            </li>
            <li onClick={() => props.refs('avaluos')}>Avalúos</li>
            <li onClick={() => props.refs('proceso')}>Proceso</li>
            <li onClick={() => props.refs('faq')}>Preguntas Frecuentes</li>
            <li onClick={() => props.refs('contacto')}>Contacto</li>
        </ul>
    )
}
export default Nav;
