import React from 'react';
import { FiCheck } from "react-icons/fi";


const avaluos = [
    "Avalúos comerciales",
    "Avalúos inmobiliarios",
    "Avalúos para Crédito FOVISSSTE",
    "Avalúos para Crédito INFONAVIT",
    "Avalúos para Crédito Hipotecario",
    "Avalúos Físcales"
]

const AboutFour = ({image}) => {
    return (
        <div className="about-area about-style-4 pt-5 mt-5">
            <div className="container">
                <div className="row row--40 align-items-center">
                    <div className="col-lg-6 ">
                        <div className="content">
                            <div className="inner">
                                <h3 className="title">Tenemos todo tipo de <br /> <strong>avalúos</strong> para tu inmueble.</h3>
                                <ul className="feature-list">
                                    {avaluos.map(item => {
                                        return(
                                            <li key={item}>
                                            <div className="icon">
                                                <FiCheck />
                                            </div>
                                            <div className="title-wrapper">
                                                <h4 className="title">{item}</h4>
                                            </div>
                                        </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <img src="./images/city/about.jpg" alt="city home avalúos"/>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default AboutFour
