import React from 'react';
import AccordionOne from '../accordion/AccordionOne';

const AbouThree = (props) => {
    return (
        <div className="rn-about-area about-style-3 rn-section-gap offset-1 col-11">
            <div className="container">
                <div className="row row--30">
                    <div className="mt_md--40 mt_sm--40">
                        <div className="inner col-11">
                            <div className="section-title">
                                <h4 className="subtitle">
                                    <span className="theme-gradient">Preguntas Frecuentes</span>
                                </h4>
                                <h2 className="title mb--40">Resuelve tus dudas sobre la valuación</h2>
                            </div>
                            <AccordionOne />
                        </div>
                    </div>
                </div>
            </div>                        
        </div>
    )
}

export default AbouThree
